body{
  background-color: #fcfbfc;
}
.ulstyle2 {
  display: flex;
  background-color:#1f1f1f;
  align-items: center;
  justify-content: right;
  height: 50px;
  transition: all .5s ease-out;
}
.ulstyle2 li {
  list-style: none;
  text-decoration: none;
}

.ulstyle2 li .link {
  margin: 0 20px;
  text-decoration: none;
  font-size: 14px;
  padding: 8px 20px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all .3s;
  color: white;
}

.ulstyle2 .link:hover{
  color: rgba(32, 143, 211, 0.874);
}
.footer {
  background: grey;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 31px;
  font-weight: 900;
  position: absolute;
  bottom: 0;
}
.footer2 {
  color: rgb(218, 218, 218);
  font-size: 10px;
  font-weight: 500;
  bottom: 0;
}




.my-form {
  padding: 2em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    color: #fff;
  background-color: lightslategrey;
  max-width: 55vh;
  margin-inline: auto;
}

h1 {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 2em;
  text-align: left;
}

.form-control {
  background-color: inherit;
  color: #fff;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding-left: 0;
}

.form-control:focus {
  background-color: inherit;
  color: #fff;
  box-shadow: none;
  border-color: #fff;
}

.btn {
  border-radius: 0;
  width: 100%;
  font-weight: bold;
}

.my-form p {
  color: rgb(215, 210, 210);
  text-align: center;
}

.my-form p a {
  color: #ffffff;
  text-decoration: none;
  font-size: small;
}

.my-form p a:hover {
  color: #fff;
}

/* AddProductForm.css */

.add-product-form {
  max-width: 400px;
  margin: 0 auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-product-form h2 {
  text-align: center;
  font-weight: 900;
  font-size: 50px;
  color: rgba(128, 128, 128, 0.541);
}

.add-product-form form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}

.add-product-form form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #555;
}

.add-product-form form button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ff6f69;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-product-form form button:hover {
  background-color: #ff4c3b;
}

.add-product-form form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.add-product-form form button:focus {
  outline: none;
}

.add-product-form .error-message {
  color: #ff4136;
  margin-top: 5px;
}


.err {
  color: red;
  font-weight: 500;
  font-size: 10px;
}

.table {
  text-align: center;
  margin: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;  width: 80%;
  text-transform: capitalize;
  user-select: none;
}

.table th {
  padding: 10px;
  background-color: green;
  /* padding: 0 10px; */
  color: rgb(201, 196, 196);
  border: 2px solid red;
}
.table td {
  padding: 3px;
  background-color: #3c3f44b9;
  color: #fff;
  transition: all .2s;
}

.table td:hover {
  background-color: #3c3f4465;
  color: black;
}

.f {
  display: flex;
  align-items: center;
  justify-content: center;
}

.se {
  outline: none;
  border: 1px solid black;
  height: 40px;
  border-radius: 2px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 400px;
  margin: 20px;
}

.jk {
  text-align: center;
  font-weight: 900;
  font-size: 50px;
  color: rgba(128, 128, 128, 0.541);
}

.warn {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-weight: 900;
  font-size: 20px;
  margin-top: 100px;
  color: rgba(128, 128, 128, 0.541) !important; 
  background-color: white !important;
}
.toggle{
  margin-top: 10px;
  color: rgb(0, 0, 0);
  font-size: 25px;
  z-index: 9999;
  display: none;
}
  .ulstyle2 li .link2{
    display: none;
  }
  .up {
    padding: 1px 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    border: none;
    font-size: small;
    color: #fff;
    background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
     background-origin: border-box;
    box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .up:focus {
    box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
    outline: 0;
  }
  .del {
    padding: 1px 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    border: none;
    font-size: small;
    color: #fff;
    background: linear-gradient(180deg, #f12d0e 0%, #db5d09 100%);
     background-origin: border-box;
    box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .del:focus {
    box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
    outline: 0;
  }

@media (max-width: 768px) {
  .del{
  padding: 1px 6px;

  }
  body{
    min-height: 100%;
  }
  
  .footer{
    position: fixed;
    bottom: 0;
    font-size: 20px;
  }
  .footer2{
    font-size: 7px;
  }
  .warn {
    text-align: center;
    font-weight: 900;
    font-size: 20px;
    margin-top: 100px;
    color: rgba(128, 128, 128, 0.541) !important; 
    background-color: white !important;
  }

.up {
  padding: 1px 4px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  border: none;
  font-size: small;
  color: #fff;
  background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
   background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.up:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}
  
  .table{
    text-transform: capitalize;
    background-color: #3c3f44 !important;
  }
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }
  .table tr{
    border-bottom: 25px solid white;
  }
  .table tbody tr td{
    text-align: right;
    padding-left: 50%;padding-right: 10px;
    position: relative;
    border: 1px solid #dee2e685 !important;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .table td::before{
    content: attr(data);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 10px;
    border-right: 1px solid rgb(255, 255, 255);
    font-size: 16px;
    text-align: left;
  }
  .ulstyle2 {
    -webkit-backdrop-filter: blur(4px);
    border-radius: 4px;
    background: hsla(0,0%,100%,.25);
    backdrop-filter: blur(4px);
    border: 1px solid hsla(0,0%,100%,.18);
    position: absolute;
    display: flex;flex-direction: column;
    padding: 1px 0px;
    color: rgb(255, 255, 255);
    top: -100%;
    height: auto;width:40px;
    z-index: 2;
    
  }
.toggle{
position: relative;
left: 9px;
color: #1f1f1f;
  display: block;
}
.open {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 40px;
}
body{
  overflow-x: hidden;
}
.ulstyle2 li .link {
  font-size: small;
  white-space: nowrap;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  color: rgb(0, 0, 0);
  }
  .my-form{
    margin: 0 20px;
  max-width: none;

  }
  .Toastify__toast {
    font-size: 14px;
    transform: scale(.7);
    /* Additional mobile-specific styles... */
  }

}